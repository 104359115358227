export const ordersUbrRules = {
    data () {
        return {

        }
    },

    computed: {
      getUbrRules () {
        if (this.$company === 'uqam') return { required: true, numeric: true, min: this.parameterLongueurCodeFacturation, max: this.parameterLongueurCodeFacturation, codeFacturationUQAMDemo: this.submitForm.code_facturation }
        return { required: true, numeric: true, min: this.parameterLongueurCodeFacturation, max: this.parameterLongueurCodeFacturation }
      },
      getUbrValidationRules () {
        if (this.$company === 'uqam') return { required: true, numeric: true, min: this.parameterLongueurCodeFacturation, max: this.parameterLongueurCodeFacturation, codeFacturationValidationUQAMDemo: [this.submitForm.code_facturation, this.compteUQAM] }
        return { required: true, numeric: true, min: this.parameterLongueurCodeFacturation, max: this.parameterLongueurCodeFacturation }
      },
    },
}
